:root {
    --navigation_card-image-placeholder: #4e5e67;
    --navigation_card-image-placeholder-color: #ffffff;
    /* Header, Breadcrumb */
    --course-header-background-color: #243539;
    --course-header-hover-backgroundColor: #12171a;
    --course-header-menu-background-color: #ffffff;
    --course-header-menu-hover-background-color: #efefef;
    --course-header-font-color: #ffffff;
    --course-header-menu-font-color: #4e5e67;
    --course-header-menu-hover-font-color: #243539;
    --course-header-menu-icon-font-color: #ffffff;
    --breadcrumb-hidden-section-background-color: #ffffff;
    --breadcrumb-hidden-section-hover-background-color: #efefef;
    --breadcrumb-hidden-section-font-color: #243539;

    /*Menu variables*/
    --menu-progress-background-color: #e1deda;
    --menu-progress-status-color: #f7931e;
    --menu-success-icon-color: #3c7d58;

    --menu-title-background-color: #ffffff;
    --menu-title-hover-background-color: #efefef;
    --menu-title-font-color: #243539;
    --menu-title-hover-font-color: #243539;

    --menu-group-title-background-color: #243539;
    --menu-group-title-hover-background-color: #12171a;
    --menu-group-title-font-color: #ffffff;
    --menu-group-title-hover-font-color: #ffffff;

    --menu-item-background-color: #ffffff;
    --menu-item-hover-background-color: #ffffff;
    --menu-item-active-background-color: #4e5e67;
    --menu-item-font-color: #243539;
    --menu-item-hover-font-color: #243539;
    --menu-item-active-font-color: #ffffff;

    /* Basic items colors (BE values) */
    --homepage-background-color: #ffffff;
    --homepage-border-color: #ffffff;
    --subpage-background-color: #585858;
    --section-background-color: #ffffff;
    --section-border-color: #ffffff;
    --homepage-nav-block-background-color: #e4e4e4;
    --homepage-nav-block-font-color: #363636;
    --nav-block-background-color: #e4e4e4;
    --nav-block-font-color: #18bce2;
    --section-title-font-color: #18bce2;
    --section-text-font-color: #363636;
    --section-link-text-font-color: #2371e7;

    --section-button-background-color: #363636;
    --section-button-border-color: #363636;
    --section-button-text-color: #18bce2;

    --focus--border--color: #2371e7;

    /* Basic items other values (local values) */
    --section-border-width: 1px;
    --section-button-boder-radius: 6px;
    --section-button-hover-background-color: #000000;

    /* Card colors: questions, concept, learning, information (BE values) */
    --card-title-font-color: #18bce2;
    --card-title-background-color: #363636;
    --card-text-font-color: #363636;
    --card-link-text-font-color: #2371e7;
    --card-background-color: #e3e3e3;
    --card-border-color: #363636;

    --card-image-background-color: #00000033;

    --card-button-background-color: #363636;
    --card-button-border-color: #363636;
    --card-button-text-color: #18bce2;
    /*TODO - accent hover?*/
    --card-button-accent-background-color: #2371e7;
    --card-button-accent-border-color: #2371e7;
    --card-button-accent-text-color: #ffffff;

    /* Card other values (local values) */
    --card-border-shadow: 0 2px 2px #4e5e67;
    --card-border-width: 1px;
    --card-background-border: var(--card-border-width) solid var(--card-border-color);
    --card-boder-radius: 4px;
    --card-button-boder-radius: 6px;
    --card-button-boder: 1px solid var(--card-button-border-color);
    --card-button-hover-background-color: #000000;
    --card-button-accent-hover-background-color: #000000;

    /* Navigation card colors (BE values) */
    --nav-card-background-color: var(--card-background-color);
    --nav-card-border-color: var(--card-border-color);
    --nav-card-text-font-color: var(--card-text-font-color);

    --nav-card-button-background-color: var(--card-button-background-color);
    --nav-card-button-border-color: var(--card-button-border-color);
    --nav-card-button-text-color: var(--card-button-text-color);

    /* Nav card other values (local values) */
    --nav-card-border-width: var(--card-border-width);
    --nav-card-boder-radius: var(--card-boder-radius);
    --nav-card-button-hover-background-color: #000000;

    /* Fonts */
    --template-primary-font-family: Rubik, sans-serif;
    --template-primary-font-weight: 700;
    --template-primary-font-lineheight: 1.2;

    --template-secondary-font-family: Poppins, sans-serif;
    --template-secondary-font-weight: 400;
    --template-secondary-font-lineheight: 1.4;

    --template-base-font-size: 16px;
    --template-base-font-mobile-size: 12px;
    --template-base-ratio-size: 1.125;
    --template-base-title-font-size: calc(var(--template-base-font-size) * var(--template-base-ratio-size) * 1px);

    /*BUTTON LOCAL*/
    --tempate-button-focus-color: #2371e7;
    --tempate-button-disabled-color: #ffffff;
    --tempate-button-disabled-background-color: #efefef;
    --tempate-button-disabled-border-color: #efefef;

    /*Question Widget*/
    --question-widget-general-color: #265aa5;
    --question-widget-explanation-general-font-color: #ffffff;
    --question-widget-correct-color: #3c7d58;
    --question-widget-explanation-correct-font-color: #ffffff;
    --question-widget-partial-correct-color: #f7931e;
    --question-widget-explanation-partial-correct-font-color: #ffffff;
    --question-widget-incorrect-color: #ba2d0b;
    --question-widget-explanation-incorrect-font-color: #ffffff;

    --question-widget-card-header-left-padding: 30px;
    --question-widget-card-introduction-bottom-padding: 10px;
    --nav-widget-card-title-bottom-padding: 15px;
    --nav-widget-card-image-side-padding: 30px;

    /*Info Widget*/
    --info-widget-stripe-width: 7px;
    --info-widget-card-top-padding: 25px;
    --info-widget-card-bottom-padding: 30px;

    /*Learning card*/
    --learning-widget-card-top-padding: 5px;
    --learning-widget-card-title-bottom-padding: 30px;

    /*Concept card*/
    --concept-widget-card-top-padding: 25px;
    --concept-widget-card-bottom-padding: 30px;

    /*Note widget*/
    --note-widget-red: #ba2d0b;
    --note-widget-background: #eed778;

    /*margins, paddings*/
    --section-margin-size: 60px;
    --section-margin-mobile-size: 20px;
    --btn-side-padding: 20px;

    /* margins, paddings of cards */
    --template-card-side-padding-small: 20px;
    --template-card-side-padding-normal: 40px;
    --template-card-side-padding-large: 60px;
    --template-card-top-bottom-padding: 20px;

    /* quill */
    --pre-text-color: var(--card-title-font-color);
    --pre-border-radius: var(--card-boder-radius);
    --pre-padding: 12px 20px;
    --pre-background-color: var(--card-title-background-color);
    --blockqoute-content-padding-small: 20px;
    --blockqoute-border-color: var(--card-title-background-color);
    --blockqoute-icon-color: var(--card-title-background-color);

    --multi_widge_player_color: #eed778;
    --multi_widge_transcript_color: #eed778;
    --multi_widge_text_link_color: #eed778;
}
