// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nitrolearning-editor-gui-primary: mat.define-palette(mat.$green-palette);
$nitrolearning-editor-gui-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nitrolearning-editor-gui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nitrolearning-editor-gui-theme: mat.define-light-theme(
    (
        color: (
            primary: $nitrolearning-editor-gui-primary,
            accent: $nitrolearning-editor-gui-accent,
            warn: $nitrolearning-editor-gui-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nitrolearning-editor-gui-theme);

/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/nitro-icons/nitroicon.scss';
@import 'assets/themes/editor-theme.css';
@import 'assets/themes/template-theme.css';
@import 'styles/maviz-fonts';
@import 'styles/template-buttons';
@import 'styles/editor-buttons.scss';
@import 'styles/input-reset';
@import 'styles/cards.scss';
@import 'styles/tooltip.scss';
@import 'styles/detail-editor';
@import 'styles/helpers';
@import 'styles/settings-custom-style';
@import 'styles/circle.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

:root {
    --header-height: 50px;
    --footer-height: 32px;
    --nitro-icon-family: 'nitroicon';
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    height: 100%;
}
body {
    margin: 0;
    background: var(--editor_main-background);
    font-family: var(--editor_main-font-family);
    font-weight: var(--editor-font-weight-normal);
    font-size: var(--editor-font-size-normal);
    min-height: 100%;

    @media (max-width: 767px) {
        --section-margin-size: var(--section-margin-mobile-size);
        .full-page-modal {
            display: none;
        }
    }
}

.mat-menu-panel.reset {
    max-width: none;

    .mat-menu-content {
        padding: 0;
    }
}

// Ez azért kell mert különben a header elcsúszik amikor modalt nyitunk
.cdk-global-scrollblock {
    position: initial;
    width: initial;
    overflow: hidden;
}

.visually-hidden {
    position: absolute;
    left: -10000em;
    top: auto;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden;
    white-space: nowrap;
}
