.detail-form {
    --detail-form-padding: 30px;
    --detail-form-horizontal-gap: 40px;
    --detail-form-left-side-min-width: 700px;
    --detail-form-right-side-min-width: 500px;

    @media (max-width: 1519px) {
        --detail-form-padding: 20px;
        --detail-form-horizontal-gap: 20px;
    }

    &.resizable {
        .detail-form__site-left {
            transition: all 0.4s;
        }

        // --detail-form-left-side-min-width: 820px;
    }

    padding: var(--detail-form-padding);
    display: flex;
    justify-content: center;
    gap: 20px var(--detail-form-horizontal-gap);
    margin: auto;
    font-family: var(--editor_main-font-family);

    &.full_size {
        width: calc(2 * 820px + var(--detail-form-horizontal-gap) + 2 * var(--detail-form-padding));
        min-width: 880px;
        max-width: 100%;
        padding: 20px var(--detail-form-padding) var(--detail-form-padding);
        //--detail-form-left-side-min-width: 820px;
        //--detail-form-right-side-min-width: 820px;

        .detail-form__site-left,
        .detail-form__site-right {
            flex: 1 1;
            min-width: unset;
        }

        //1300 = --detail-form-padding: 30px * 2 +
        //   --detail-form-horizontal-gap: 40px +
        //   --detail-form-left-side-min-width: 600px +
        //   --detail-form-right-side-min-width: 600px
        @media (max-width: 1299px) {
            flex-direction: column;
            width: 880px;
            max-width: 100%;
        }

        @media (max-width: 939px) {
            min-width: unset;
        }
    }

    &.margin,
    &.original {
        .detail-form__site-left {
            width: 700px;
        }
    }

    &.resizable.no_margin {
        .detail-form__site-left {
            width: 820px;
        }
    }

    &:not(.full_size).resizable {
        // 1520 = --detail-form-padding: 30px * 2 +
        //    --detail-form-horizontal-gap: 40px +
        //    --detail-form-left-side-min-width: 820px +
        //    --detail-form-right-side-min-width: 600px
        @media (max-width: 1399px) {
            flex-direction: column;
            align-items: center;
            .detail-form__site-left {
                min-width: unset;
                width: 100%;
                align-items: center;
            }

            &.no_margin {
                width: calc(820px + 2 * var(--detail-form-padding));
                max-width: 100%;
            }
            &.margin,
            &.original {
                width: calc(700px + 2 * var(--detail-form-padding));
                max-width: 100%;
            }
        }
    }

    &:not(.resizable) {
        // 1400 = --detail-form-padding: 30px * 2 +
        //    --detail-form-horizontal-gap: 40px +
        //    --detail-form-left-side-min-width: 700px +
        //    --detail-form-right-side-min-width: 600px
        @media (max-width: 1399px) {
            flex-direction: column;
            align-items: center;
            .detail-form__site-left,
            .detail-form__site-right {
                width: 100%;
                max-width: 700px;
            }
        }
    }
    &:not(.resizable) .detail-form__real-time-widget {
        max-width: 700px;
    }
    .detail-form__real-time-widget {
        width: 100%;

        &.full_size {
            max-width: unset;
            width: 100%;
            padding: 0;
        }
    }

    &__site-left,
    &__site-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 1399px) {
            max-width: 820px;
        }
    }

    &__site-left {
        height: fit-content;
        align-items: flex-end;
        min-width: var(--detail-form-left-side-min-width);

        // 760 = --detail-form-padding: 30px * 2 +
        //    --detail-form-right-side-min-width: 600px
        @media (max-width: 759px) {
            min-width: unset;
        }
    }

    &__site-right {
        min-width: var(--detail-form-right-side-min-width);
        width: 100%;
        flex: 1;

        // 660 = --detail-form-padding: 30px * 2 +
        //    --detail-form-right-side-min-width: 600px
        @media (max-width: 659px) {
            min-width: unset;
        }
    }

    .card {
        margin: 0;
        width: 100%;
        .detail-form__group,
        fieldset.detail-form__group {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    &__checkbox {
        margin-bottom: 12px;
    }

    &__header {
        height: 50px;
        display: flex;
        align-items: center;
        max-width: 1740px;
        margin: auto;

        &.actions-blocks {
            justify-content: space-between;
        }

        &.full_size {
            max-width: unset;
            .detail-form__header__action-buttons {
                margin-left: 50px;
            }
        }
        &.no_margin .detail-form__header__action-buttons {
            padding-left: 30px; // var(--detail-form-padding) = 30px
        }
        &.margin .detail-form__header__action-buttons {
            padding-left: 30px; //  (820 - 700) + 30
        }

        @media (max-width: 1519px) {
            &.no_margin .detail-form__header__action-buttons {
                padding-left: 20px; // var(--detail-form-padding) = 30px
            }
            &.margin .detail-form__header__action-buttons {
                padding-left: 20px; //  (820 - 700) + 30
            }
        }

        @media (max-width: 1399px) {
            &.no_margin {
                width: 880px; // 820 + 3 * 30
            }
            &.margin,
            &.original {
                width: 760px; // 700 + 2 * 30
                .detail-form__header__action-buttons {
                    padding-left: 30px;
                }
            }
        }

        @media (max-width: 920px) {
            &.no_margin {
                width: 100%;
            }
        }

        &__action-buttons {
            display: flex;
            align-items: center;

            .editor-btn {
                &:not(:last-child) {
                    border-right-width: thin;
                }
                &:not(:first-child) {
                    border-left-width: thin;
                }
            }
            .new-card-btn {
                font-size: var(--editor-font-size-normal);
            }
        }

        &__action-buttons-wrapper {
            display: flex;
            align-items: center;

            .editor-btn {
                &:not(:last-child) {
                    border-right-width: thin;
                }
                &:not(:first-child) {
                    border-left-width: thin;
                }
            }
        }
    }

    &__number-block {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    fieldset.detail-form__group {
        background-color: var(--editor_detail-card-radio-background);
        padding: 0;
        margin: 0;
        border: 0;
        &:disabled {
            opacity: 0.5;
        }
    }
    &__group {
        /*max-width: 700px;*/
        width: 100%;
        display: flex;
        flex-direction: column;

        &__label {
            font-weight: var(--editor_detail-card-radio-legend-font-weight);
            color: var(--editor_button-primary-text-color);
            font-size: 16px;
            padding: 0;
            margin-bottom: 5px;
        }
    }

    &__input,
    textarea {
        border: 1px solid var(--editor-widget-color);
        padding: 10px;
        resize: vertical;
        min-height: 52px;
        outline: none;
        max-height: 400px;
        width: 100%;
        font-size: 16px;
        font-family: var(--editor_main-font-family);

        &:hover,
        &:focus {
            box-shadow: 0 0 3px var(--editor-widget-color);
        }
    }
}
