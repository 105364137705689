:root {
    --editor_main-font-family: Rubik, sans-serif;
    --editor_secondary-font-family: Poppins, sans-serif;
    --editor-font-weight-normal: 400;
    --editor-font-weight-medium: 500;
    --editor-font-weight-semi-medium: 600;
    --editor-font-weight-bold: 700;

    --editor-main-font-color: #243539;
    --editor-placeholder-color: #4e5e67;

    --editor-font-style-normal: normal;
    --editor-font-style-italic: italic;

    --editor-font-size-small: 14px;
    --editor-font-size-normal: 16px;

    --editor_main-background: #e1deda;
    --editor_error-color: #ba2d0b;

    --editor_focus-color: #2371e7;
    --editor_focus-color-light: #2371e777;

    /* Ghost  */
    --editor_ghost-background: #e1deda;
    --editor_ghost-color: #ffffff;

    --editor_full-modal-background: #ffffff;
    --editor_full-modal-header-border: 2px dashed #4e5e67;
    /*header*/
    --editor_header-background: #ffffff;
    --editor_header-shadow: #00000029;
    --editor_search-color: #4e5e67;
    --editor-search-border-color: #e1deda;
    --editor-search-border-focused-color: #2371e7;

    /*footer*/
    --editor_footer-background: #ffffff;
    --editor_footer-border-color: #efefef;
    --editor_footer-text-color: #243539;

    /*User card*/
    --editor_user-card-background: #ffffff;
    --editor-user-card-color: #4e5e67;
    --editor-user-card-text-border: 1px solid #e1deda;
    --editor_user-card-backgrfooter-hover: #f1f3f4;
    --editor_user-card-shadow: #00000029;
    --editor_user-card-link-color: #2371e7;

    /*Info card*/
    --editor-info-card-text-color: #243539;
    --editor-info-card-progress-color: #2371e7;
    --editor-info-card-remaining-color: #e1deda;

    /*Enrollment warn*/
    --enrollment-almost-full-progress-color: #f08024;
    --enrollment-full-progress-color: #b12b0b;

    /*left sidebar*/
    --editor_left-sidebar-background: #ffffff;
    --editor_left-sidebar-highlight-background: #243539;
    --editor_left-sidebar-highlight-color: #ffffff;
    --editor_left-sidebar-text-color: #243539;
    --editor_left-sidebar-hover-background: #efefef;
    --editor_left-sidebar-border-top: 1px solid #efefef;
    --editor_left-sidebar-placeholder-color: #4e5e67;
    --editor_new-chapter-background: #2371e7;
    --editor_sidebar-tooltip-background: #4e5e67;
    --editor_sidebar-tooltip-color: #ffffff;

    /*right sidebar*/
    --editor_right-sidebar-background: #ffffff;
    --editor_right-sidebar-highlight-background: #243539;
    --editor_right-sidebar-highlight-color: #ffffff;
    --editor_right-sidebar-text-color: #243539;
    --editor_right-sidebar-hover-background: #efefef;
    --editor_right-sidebar-placeholder-color: #4e5e67;

    /*cards*/
    --editor_cards-border: 1px solid #4e5e67;
    --editor_cards-border-radius: 2px;

    /*new card*/
    --editor_new-card-header-background: #4e5e67;
    --editor_new-card-icon-border-radius: 50%;
    --editor_new-card-icon-color: #4e5e67;

    /*card*/
    --editor_card-image-placeholder: #243539;
    --editor_card-image-color: #ffffff;
    --editor_card-title-color: #243539;
    --editor_card-code-color: #243539;
    --editor_card-author-color: #243539;
    --editor_card-header-color: #243539;

    --editor_card-date-color: #4e5e67;
    --editor_card-footer-background: #ffffff;

    /*form checkbox*/
    --editor_checkbox-border-color: #4e5e67;
    --editor_checkbox-border-hover-color: #243539;
    --editor_checkbox-background: #ffffff;
    --editor_checkbox-checked: #f7931e;

    /*form radio button*/
    --editor_radio-border-color: #4e5e67;
    --editor_radio-border-hover-color: #243539;
    --editor_radio-background: #ffffff;
    --editor_radio-checked: #f7931e;

    /*fonts*/
    --editor_font-title-size: 28px;
    --editor_font-label-size: 16px;
    --editor_font-button-size: 16px;
    --editor_font-link-size: 14px;

    /*detail editor card*/
    --editor_detail-card-border: 1px solid #4e5e67;
    --editor_detail-card-border-radius: 4px;
    --editor_detail-card-min-width: 820px;
    --editor_detail-card-icon-font-size: 20px;
    --editor_detail-card-body-background: #ffffff;
    --editor_detail-card-body-top-border: 2px dotted #243539;
    --editor_detail-card-footer-background: #ffffff;

    /*detail editor card header*/
    --editor_detail-card-header-icon-color: #4e5e67;
    --editor_detail-card-header-background: #ffffff;
    --editor_detail-card-header-color: #243539;
    --editor_detail-card-header-font-size: 20px;
    --editor_detail-card-header-font-wight: 600;
    --editor_detail-card-header-line-height: 24px;
    --editor_detail-card-header-height: 50px;

    /*form of detail editor cards radio button*/
    --editor_detail-card-radio-border-color: #4e5e67;
    --editor_detail-card-radio-border-hover-color: #4e5e67;
    --editor_detail-card-radio-background: #ffffff;
    --editor_detail-card-radio-checked: #243539;
    --editor_detail-card-radio-legend-font-weight: 500;
    --editor_detail-card-radio-legend-color: #243539;

    /*widget action button*/
    --editor_drop-placeholder-color: rgba(78, 94, 103, 0.4);
    --editor-drop-placeholder-border: 2px dotted var(--editor_drop-placeholder-color);
    --editor-widget-color: #4e5e67;
    --editor-widget-border: 2px dotted var(--section-button-border-color);
    --editor_widget-selector-border: 2px solid var(--section-button-border-color);
    --editor_action-background-color: #ffffff;

    /*page-tree*/
    --editor_tree-color: #243539;
    --editor_tree-arrow-color: #4e5e67;
    --editor_tree-background-color: transparent;
    --editor_tree-hover-color: #243539;
    --editor_tree-arrow-hover-color: #243539;
    --editor_tree-hover-background-color: #efefef;
    --editor_tree-active-color: #ffffff;
    --editor_tree-active-arrow-color: #e1deda;
    --editor_tree-active-hover-arrow-color: #ffffff;
    --editor_tree-active-background-color: #243539;

    /*toast message*/
    --editor_toast-error-background: #b12b0b;
    --editor_toast-info-background: #175cc2;
    --editor_toast-warrning-background: #f7931e;
    --editor_toast-success-background: #3c7d58;
    --editor_toast-color: #ffffff;
    --editor_toast-font-size: 14px;

    /* 404 */
    --editor_page-not-found-title-color: #51aa77;
    --editor_page-not-found-title-font-size: 336px;
    --editor_page-not-found-subtitle-font-size: 25px;
    --editor_page-not-found-subtitle-color: #333333;
    --editor_page-not-found-details-font-size: 14px;
    --editor_page-not-found-details-color: #243539;

    /*File upload*/
    --editor_file-upload-border: 1px solid #4e5e67;
    --editor_image-placeholder-background: #e1deda;

    /* settings */
    --editor_settings-modal-nav-button-hover-background: #efefef;
    --editor_settings-modal-nav-button-active-background: #2371e7;
    --editor_settings-modal-nav-button-active: #ffffff;
    --editor_settings-modal-form-background: #ffffff;
    --editor_settings-modal-preview-background: #e1deda;
    --editor_settings-input-border-color: #4e5e67;

    --editor-items-background-color: #ffffff;
    --editor-border-background-color: #ffffff;

    /* publish */
    --editor_publish-modal-thead-background: #4e5e67;
    --editor_publish-modal-thead-color: #ffffff;
    --editor_publish-modal-border: 1px solid #243539;
    --editor_publish-modal-pagination-link: #2371e7;
    --editor_publish-modal-pagination-border: 1px solid #e1deda;
    --editor_publish-modal-active-version-background: #e1deda;

    /* loader */
    --editor_loader-background: #efefef;
    --editor_loader-text: #243539;

    /*buttons*/
    --editor_button-primary-text-color: #243539;
    --editor_button-primary-border-color: #4e5e67;
    --editor_button-primary-background-color: #ffffff;
    --editor_button-primary-hover-text-color: #12171a;
    --editor_button-primary-hover-background-color: #efefef;
    --editor_button-primary-hoverborder-color: #efefef;

    --editor_button-secondary-text-color: #ffffff;
    --editor_button-secondary-border-color: #4e5e67;
    --editor_button-secondary-background-color: #4e5e67;
    --editor_button-secondary-hover-text-color: #ffffff;
    --editor_button-secondary-hover-background-color: #243539;
    --editor_button-secondary-hover-border-color: #243539;
    --editor_button-secondary-blue-border-color: #2371e7;
    --editor_button-secondary-blue-background-color: #2371e7;
    --editor_button-secondary-blue-hover-background-color: #1c5bb9;

    --editor_button-danger-text-color: #ba2d0b;
    --editor_button-danger-border-color: #ba2d0b;
    --editor_button-danger-background-color: #ffffff;
    --editor_button-danger-hover-text-color: #ffffff;
    --editor_button-danger-hover-background-color: #8c2208;

    --editor_button-orange-background-color: #ffffff;
    --editor_button-orange-text-color: #f7931e;
    --editor_button-orange-border-color: #f7931e;
    --editor_button-orange-hover-background-color: #f7931e;
    --editor_button-orange-hover-text-color: #ffffff;

    --editor_button-accent-background-color: #2371e7;
    --editor_button-accent-text-color: #ffffff;
    --editor_button-accent-border-color: #2371e7;
    --editor_button-accent-hover-background-color: #175cc2;
    --editor_button-accent-hover-text-color: #ffffff;

    --editor_button-focus-color: #2371e7;
    --editor-button-disabled-color: #ffffff;
    --editor-button-disabled-background-color: #efefef;
    --editor-button-disabled-border-color: #efefef;

    /* account */
    --editor_account-settings-color: #243539;
    --editor_account-settings-active-bg: #243539;
    --editor_account-settings-active-color: #ffffff;

    --editor_account-users-thead-background: #4e5e67;
    --editor_account-users-thead-color: #ffffff;

    /* Error list */
    --editor_validation-warning: #b12b0b;
    --editor_validation-notice: #175cc2;
    --editor_validation-font-color: #ffffff;
}
