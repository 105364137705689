@font-face {
    font-family: 'nitroicon';
    src: url('nitroicon.eot?df55f0eb3e244d8d157aa4b1d57c36f8?#iefix') format('embedded-opentype'),
        url('nitroicon.woff2?df55f0eb3e244d8d157aa4b1d57c36f8') format('woff2'),
        url('nitroicon.woff?df55f0eb3e244d8d157aa4b1d57c36f8') format('woff'),
        url('nitroicon.ttf?df55f0eb3e244d8d157aa4b1d57c36f8') format('truetype'),
        url('nitroicon.svg?df55f0eb3e244d8d157aa4b1d57c36f8#nitroicon') format('svg');
}

span[class^='nitroicon-']:before,
span[class*=' nitroicon-']:before {
    font-family: 'nitroicon' !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nitroicon-add_page:before {
    content: '\f101';
}
.nitroicon-add-v2:before {
    content: '\f102';
}
.nitroicon-add:before {
    content: '\f103';
}
.nitroicon-anchor:before {
    content: '\f104';
}
.nitroicon-arrow_down:before {
    content: '\f105';
}
.nitroicon-arrow_right:before {
    content: '\f106';
}
.nitroicon-arrow_up:before {
    content: '\f107';
}
.nitroicon-audio_pause:before {
    content: '\f108';
}
.nitroicon-audio_play:before {
    content: '\f109';
}
.nitroicon-audio_text_selector:before {
    content: '\f10a';
}
.nitroicon-autolayout:before {
    content: '\f10b';
}
.nitroicon-back_right:before {
    content: '\f10c';
}
.nitroicon-back:before {
    content: '\f10d';
}
.nitroicon-block_below:before {
    content: '\f10e';
}
.nitroicon-block_fullsize:before {
    content: '\f10f';
}
.nitroicon-block_mid:before {
    content: '\f110';
}
.nitroicon-block_narrow:before {
    content: '\f111';
}
.nitroicon-block:before {
    content: '\f112';
}
.nitroicon-blockquote:before {
    content: '\f113';
}
.nitroicon-blocks:before {
    content: '\f114';
}
.nitroicon-board:before {
    content: '\f115';
}
.nitroicon-bold:before {
    content: '\f116';
}
.nitroicon-book:before {
    content: '\f117';
}
.nitroicon-button:before {
    content: '\f118';
}
.nitroicon-caption_off:before {
    content: '\f119';
}
.nitroicon-caption_on:before {
    content: '\f11a';
}
.nitroicon-cards:before {
    content: '\f11b';
}
.nitroicon-carousel-zoom:before {
    content: '\f11c';
}
.nitroicon-carousel:before {
    content: '\f11d';
}
.nitroicon-check-circle:before {
    content: '\f11e';
}
.nitroicon-check:before {
    content: '\f11f';
}
.nitroicon-close:before {
    content: '\f120';
}
.nitroicon-cloud:before {
    content: '\f121';
}
.nitroicon-cloze_question:before {
    content: '\f122';
}
.nitroicon-code:before {
    content: '\f123';
}
.nitroicon-concept_card:before {
    content: '\f124';
}
.nitroicon-copy:before {
    content: '\f125';
}
.nitroicon-courses:before {
    content: '\f126';
}
.nitroicon-cut:before {
    content: '\f127';
}
.nitroicon-delete:before {
    content: '\f128';
}
.nitroicon-desktop:before {
    content: '\f129';
}
.nitroicon-dia-widget-icon:before {
    content: '\f12a';
}
.nitroicon-divider:before {
    content: '\f12b';
}
.nitroicon-document:before {
    content: '\f12c';
}
.nitroicon-double-arrow-left:before {
    content: '\f12d';
}
.nitroicon-download:before {
    content: '\f12e';
}
.nitroicon-draftcard:before {
    content: '\f12f';
}
.nitroicon-edit:before {
    content: '\f130';
}
.nitroicon-embed:before {
    content: '\f131';
}
.nitroicon-equal_sides:before {
    content: '\f132';
}
.nitroicon-error:before {
    content: '\f133';
}
.nitroicon-exam:before {
    content: '\f134';
}
.nitroicon-fejezet:before {
    content: '\f135';
}
.nitroicon-fill_cut_out_excess:before {
    content: '\f136';
}
.nitroicon-finish_flag:before {
    content: '\f137';
}
.nitroicon-folder:before {
    content: '\f138';
}
.nitroicon-format_clear:before {
    content: '\f139';
}
.nitroicon-forward_10:before {
    content: '\f13a';
}
.nitroicon-full_image:before {
    content: '\f13b';
}
.nitroicon-fullscreen_exit:before {
    content: '\f13c';
}
.nitroicon-fullscreen:before {
    content: '\f13d';
}
.nitroicon-gps:before {
    content: '\f13e';
}
.nitroicon-group_question:before {
    content: '\f13f';
}
.nitroicon-H1:before {
    content: '\f140';
}
.nitroicon-H2:before {
    content: '\f141';
}
.nitroicon-hamburger:before {
    content: '\f142';
}
.nitroicon-hammer:before {
    content: '\f143';
}
.nitroicon-highlighted_text_selector:before {
    content: '\f144';
}
.nitroicon-horizontal:before {
    content: '\f145';
}
.nitroicon-image_and_text:before {
    content: '\f146';
}
.nitroicon-image_image_order_1-2:before {
    content: '\f147';
}
.nitroicon-image_image_order_2-1:before {
    content: '\f148';
}
.nitroicon-image_image_selector:before {
    content: '\f149';
}
.nitroicon-image_image-ratio_1-1:before {
    content: '\f14a';
}
.nitroicon-image_image-ratio_1-3:before {
    content: '\f14b';
}
.nitroicon-image_image-ratio_3-1:before {
    content: '\f14c';
}
.nitroicon-image_text_selector:before {
    content: '\f14d';
}
.nitroicon-image_text:before {
    content: '\f14e';
}
.nitroicon-image:before {
    content: '\f14f';
}
.nitroicon-imagefit_contain:before {
    content: '\f150';
}
.nitroicon-imagefit_cover:before {
    content: '\f151';
}
.nitroicon-imagefit_padded:before {
    content: '\f152';
}
.nitroicon-Import:before {
    content: '\f153';
}
.nitroicon-info_card:before {
    content: '\f154';
}
.nitroicon-info:before {
    content: '\f155';
}
.nitroicon-infographic_image:before {
    content: '\f156';
}
.nitroicon-informative_image:before {
    content: '\f157';
}
.nitroicon-italic:before {
    content: '\f158';
}
.nitroicon-key:before {
    content: '\f159';
}
.nitroicon-learning_card:before {
    content: '\f15a';
}
.nitroicon-lecke_recommend:before {
    content: '\f15b';
}
.nitroicon-left_side_emphasis:before {
    content: '\f15c';
}
.nitroicon-link:before {
    content: '\f15d';
}
.nitroicon-list-bullet:before {
    content: '\f15e';
}
.nitroicon-list-ordered:before {
    content: '\f15f';
}
.nitroicon-lock:before {
    content: '\f160';
}
.nitroicon-logo:before {
    content: '\f161';
}
.nitroicon-logout:before {
    content: '\f162';
}
.nitroicon-magnifying_glass:before {
    content: '\f163';
}
.nitroicon-matching_question:before {
    content: '\f164';
}
.nitroicon-mobil_landscape:before {
    content: '\f165';
}
.nitroicon-mobile:before {
    content: '\f166';
}
.nitroicon-more_hor:before {
    content: '\f167';
}
.nitroicon-more_vert:before {
    content: '\f168';
}
.nitroicon-move_down:before {
    content: '\f169';
}
.nitroicon-move_up:before {
    content: '\f16a';
}
.nitroicon-move:before {
    content: '\f16b';
}
.nitroicon-multi_question:before {
    content: '\f16c';
}
.nitroicon-multi_widgets_selector:before {
    content: '\f16d';
}
.nitroicon-new_chapter:before {
    content: '\f16e';
}
.nitroicon-new_course:before {
    content: '\f16f';
}
.nitroicon-osszes_tananyag:before {
    content: '\f170';
}
.nitroicon-osztott_blokk:before {
    content: '\f171';
}
.nitroicon-paste:before {
    content: '\f172';
}
.nitroicon-Pause:before {
    content: '\f173';
}
.nitroicon-pdf_embed_icon:before {
    content: '\f174';
}
.nitroicon-pdf_text_selector:before {
    content: '\f175';
}
.nitroicon-play:before {
    content: '\f176';
}
.nitroicon-plussz:before {
    content: '\f177';
}
.nitroicon-profile:before {
    content: '\f178';
}
.nitroicon-publish:before {
    content: '\f179';
}
.nitroicon-question:before {
    content: '\f17a';
}
.nitroicon-quiz:before {
    content: '\f17b';
}
.nitroicon-quote:before {
    content: '\f17c';
}
.nitroicon-replay_10:before {
    content: '\f17d';
}
.nitroicon-restore:before {
    content: '\f17e';
}
.nitroicon-right_side_emphasis:before {
    content: '\f17f';
}
.nitroicon-save_and_back:before {
    content: '\f180';
}
.nitroicon-save:before {
    content: '\f181';
}
.nitroicon-school:before {
    content: '\f182';
}
.nitroicon-settings-36:before {
    content: '\f183';
}
.nitroicon-settings:before {
    content: '\f184';
}
.nitroicon-simple_image:before {
    content: '\f185';
}
.nitroicon-single_question:before {
    content: '\f186';
}
.nitroicon-sorting_question:before {
    content: '\f187';
}
.nitroicon-sorting-selector:before {
    content: '\f188';
}
.nitroicon-speaker:before {
    content: '\f189';
}
.nitroicon-stats:before {
    content: '\f18a';
}
.nitroicon-strike:before {
    content: '\f18b';
}
.nitroicon-study:before {
    content: '\f18c';
}
.nitroicon-sub:before {
    content: '\f18d';
}
.nitroicon-success:before {
    content: '\f18e';
}
.nitroicon-super:before {
    content: '\f18f';
}
.nitroicon-switch_sides:before {
    content: '\f190';
}
.nitroicon-table:before {
    content: '\f191';
}
.nitroicon-tablet_landscape:before {
    content: '\f192';
}
.nitroicon-tablet:before {
    content: '\f193';
}
.nitroicon-text_and_image:before {
    content: '\f194';
}
.nitroicon-Text_hiding:before {
    content: '\f195';
}
.nitroicon-text_image:before {
    content: '\f196';
}
.nitroicon-Text_show:before {
    content: '\f197';
}
.nitroicon-text_widget_format_align_center:before {
    content: '\f198';
}
.nitroicon-text_widget_format_align_left:before {
    content: '\f199';
}
.nitroicon-text_widget_format_align_right:before {
    content: '\f19a';
}
.nitroicon-text:before {
    content: '\f19b';
}
.nitroicon-theme:before {
    content: '\f19c';
}
.nitroicon-title_image:before {
    content: '\f19d';
}
.nitroicon-tooltip_down:before {
    content: '\f19e';
}
.nitroicon-tooltip_left:before {
    content: '\f19f';
}
.nitroicon-tooltip_marker:before {
    content: '\f1a0';
}
.nitroicon-tooltip_right:before {
    content: '\f1a1';
}
.nitroicon-tooltip_up:before {
    content: '\f1a2';
}
.nitroicon-underline:before {
    content: '\f1a3';
}
.nitroicon-upload:before {
    content: '\f1a4';
}
.nitroicon-Vector:before {
    content: '\f1a5';
}
.nitroicon-vertical:before {
    content: '\f1a6';
}
.nitroicon-video_pause:before {
    content: '\f1a7';
}
.nitroicon-video_play:before {
    content: '\f1a8';
}
.nitroicon-video_settings:before {
    content: '\f1a9';
}
.nitroicon-video_text_selector:before {
    content: '\f1aa';
}
.nitroicon-video:before {
    content: '\f1ab';
}
.nitroicon-visibility_off:before {
    content: '\f1ac';
}
.nitroicon-visibility-on:before {
    content: '\f1ad';
}
.nitroicon-voice:before {
    content: '\f1ae';
}
.nitroicon-volume_down:before {
    content: '\f1af';
}
.nitroicon-volume_mute:before {
    content: '\f1b0';
}
.nitroicon-volume_up:before {
    content: '\f1b1';
}
.nitroicon-warn:before {
    content: '\f1b2';
}
.nitroicon-warning:before {
    content: '\f1b3';
}
.nitroicon-youTube_selector_icon:before {
    content: '\f1b4';
}

$icons: (
    'add_page': '\f101',
    'add-v2': '\f102',
    'add': '\f103',
    'anchor': '\f104',
    'arrow_down': '\f105',
    'arrow_right': '\f106',
    'arrow_up': '\f107',
    'audio_pause': '\f108',
    'audio_play': '\f109',
    'audio_text_selector': '\f10a',
    'autolayout': '\f10b',
    'back_right': '\f10c',
    'back': '\f10d',
    'block_below': '\f10e',
    'block_fullsize': '\f10f',
    'block_mid': '\f110',
    'block_narrow': '\f111',
    'block': '\f112',
    'blockquote': '\f113',
    'blocks': '\f114',
    'board': '\f115',
    'bold': '\f116',
    'book': '\f117',
    'button': '\f118',
    'caption_off': '\f119',
    'caption_on': '\f11a',
    'cards': '\f11b',
    'carousel-zoom': '\f11c',
    'carousel': '\f11d',
    'check-circle': '\f11e',
    'check': '\f11f',
    'close': '\f120',
    'cloud': '\f121',
    'cloze_question': '\f122',
    'code': '\f123',
    'concept_card': '\f124',
    'copy': '\f125',
    'courses': '\f126',
    'cut': '\f127',
    'delete': '\f128',
    'desktop': '\f129',
    'dia-widget-icon': '\f12a',
    'divider': '\f12b',
    'document': '\f12c',
    'double-arrow-left': '\f12d',
    'download': '\f12e',
    'draftcard': '\f12f',
    'edit': '\f130',
    'embed': '\f131',
    'equal_sides': '\f132',
    'error': '\f133',
    'exam': '\f134',
    'fejezet': '\f135',
    'fill_cut_out_excess': '\f136',
    'finish_flag': '\f137',
    'folder': '\f138',
    'format_clear': '\f139',
    'forward_10': '\f13a',
    'full_image': '\f13b',
    'fullscreen_exit': '\f13c',
    'fullscreen': '\f13d',
    'gps': '\f13e',
    'group_question': '\f13f',
    'H1': '\f140',
    'H2': '\f141',
    'hamburger': '\f142',
    'hammer': '\f143',
    'highlighted_text_selector': '\f144',
    'horizontal': '\f145',
    'image_and_text': '\f146',
    'image_image_order_1-2': '\f147',
    'image_image_order_2-1': '\f148',
    'image_image_selector': '\f149',
    'image_image-ratio_1-1': '\f14a',
    'image_image-ratio_1-3': '\f14b',
    'image_image-ratio_3-1': '\f14c',
    'image_text_selector': '\f14d',
    'image_text': '\f14e',
    'image': '\f14f',
    'imagefit_contain': '\f150',
    'imagefit_cover': '\f151',
    'imagefit_padded': '\f152',
    'Import': '\f153',
    'info_card': '\f154',
    'info': '\f155',
    'infographic_image': '\f156',
    'informative_image': '\f157',
    'italic': '\f158',
    'key': '\f159',
    'learning_card': '\f15a',
    'lecke_recommend': '\f15b',
    'left_side_emphasis': '\f15c',
    'link': '\f15d',
    'list-bullet': '\f15e',
    'list-ordered': '\f15f',
    'lock': '\f160',
    'logo': '\f161',
    'logout': '\f162',
    'magnifying_glass': '\f163',
    'matching_question': '\f164',
    'mobil_landscape': '\f165',
    'mobile': '\f166',
    'more_hor': '\f167',
    'more_vert': '\f168',
    'move_down': '\f169',
    'move_up': '\f16a',
    'move': '\f16b',
    'multi_question': '\f16c',
    'multi_widgets_selector': '\f16d',
    'new_chapter': '\f16e',
    'new_course': '\f16f',
    'osszes_tananyag': '\f170',
    'osztott_blokk': '\f171',
    'paste': '\f172',
    'Pause': '\f173',
    'pdf_embed_icon': '\f174',
    'pdf_text_selector': '\f175',
    'play': '\f176',
    'plussz': '\f177',
    'profile': '\f178',
    'publish': '\f179',
    'question': '\f17a',
    'quiz': '\f17b',
    'quote': '\f17c',
    'replay_10': '\f17d',
    'restore': '\f17e',
    'right_side_emphasis': '\f17f',
    'save_and_back': '\f180',
    'save': '\f181',
    'school': '\f182',
    'settings-36': '\f183',
    'settings': '\f184',
    'simple_image': '\f185',
    'single_question': '\f186',
    'sorting_question': '\f187',
    'sorting-selector': '\f188',
    'speaker': '\f189',
    'stats': '\f18a',
    'strike': '\f18b',
    'study': '\f18c',
    'sub': '\f18d',
    'success': '\f18e',
    'super': '\f18f',
    'switch_sides': '\f190',
    'table': '\f191',
    'tablet_landscape': '\f192',
    'tablet': '\f193',
    'text_and_image': '\f194',
    'Text_hiding': '\f195',
    'text_image': '\f196',
    'Text_show': '\f197',
    'text_widget_format_align_center': '\f198',
    'text_widget_format_align_left': '\f199',
    'text_widget_format_align_right': '\f19a',
    'text': '\f19b',
    'theme': '\f19c',
    'title_image': '\f19d',
    'tooltip_down': '\f19e',
    'tooltip_left': '\f19f',
    'tooltip_marker': '\f1a0',
    'tooltip_right': '\f1a1',
    'tooltip_up': '\f1a2',
    'underline': '\f1a3',
    'upload': '\f1a4',
    'Vector': '\f1a5',
    'vertical': '\f1a6',
    'video_pause': '\f1a7',
    'video_play': '\f1a8',
    'video_settings': '\f1a9',
    'video_text_selector': '\f1aa',
    'video': '\f1ab',
    'visibility_off': '\f1ac',
    'visibility-on': '\f1ad',
    'voice': '\f1ae',
    'volume_down': '\f1af',
    'volume_mute': '\f1b0',
    'volume_up': '\f1b1',
    'warn': '\f1b2',
    'warning': '\f1b3',
    'youTube_selector_icon': '\f1b4',
);

@function nitroicon-font($name) {
    @return map-get($icons, $name);
}
