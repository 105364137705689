@font-face {
    font-family: 'Univers Condensed', sans-serif;
    src: url('../assets/custom-fonts/maviz/Univers-Condensed-Medium.woff2') format('woff2'),
        url('../assets/custom-fonts/maviz/Univers-Condensed-Medium.woff') format('woff'),
        url('../assets/custom-fonts/maviz/unvr57w.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers Condensed', sans-serif;
    src: url('../assets/custom-fonts/maviz/Univers-Condensed-BoldItalic.woff2') format('woff2'),
        url('../assets/custom-fonts/maviz/Univers-Condensed-BoldItalic.woff') format('woff'),
        url('../assets/custom-fonts/maviz/unvr58w.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers Condensed', sans-serif;
    src: url('../assets/custom-fonts/maviz/Univers-Condensed-Bold.woff2') format('woff2'),
        url('../assets/custom-fonts/maviz/Univers-Condensed-Bold.woff') format('woff'),
        url('../assets/custom-fonts/maviz/unvr67w.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers Condensed', sans-serif;
    src: url('../assets/custom-fonts/maviz/Univers-Condensed-MediumItalic.woff2') format('woff2'),
        url('../assets/custom-fonts/maviz/Univers-Condensed-MediumItalic.woff') format('woff'),
        url('../assets/custom-fonts/maviz/unvr68w.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
