.full-page-modal.inner-component-max-height {
    .full-page-modal__inner-component-wrapper {
        height: 100%;
    }
}

.setting-compnent {
    height: 100%;
}

.setting-options {
    background-color: var(--editor_settings-modal-form-background);
}

.settings-form {
    padding: 2rem;

    .group-main-title {
        font-weight: 700;
        font-size: 18px;
        margin: 20px 0;
    }

    .settings-form {
        &__group {
            padding: 0;
            margin: 0 0 1rem;
            border: none;
            display: flex;
            align-items: center;

            legend {
                padding: 0;
                margin-bottom: 6px;
                font-weight: 500;
            }

            label {
                display: block;
                font-weight: 600;
                margin-bottom: 5px;
            }

            input {
                height: 2rem;
                border-radius: 2px;
                border-width: 1px;
                border-color: var(--editor_settings-input-border-color);
                padding: 10px;
                width: 100%;
                margin: 0 0.5rem;
                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }

            input[type='checkbox'] {
                cursor: pointer;
            }

            mat-select.select-input {
                border-radius: 2px;
            }
        }

        &__elem-wrapper {
            &.flex-1 {
                flex: 1;
            }
        }
    }
}

.setting-preview {
    background-color: var(--editor_settings-modal-preview-background);
    padding: 2rem 3rem;
    width: 100%;
}
