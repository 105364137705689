.card {
    border-radius: var(--editor_detail-card-border-radius);
    border: var(--editor_detail-card-border);
    margin: 0 auto 12px;

    & > :first-child {
        border-radius: var(--editor_detail-card-border-radius);
    }

    & > :last-child {
        border-bottom-left-radius: var(--editor_detail-card-border-radius);
        border-bottom-right-radius: var(--editor_detail-card-border-radius);
    }

    &__header {
        background-color: var(--editor_detail-card-header-background);
        color: var(--editor_detail-card-header-color);
        font-weight: var(--editor_detail-card-header-font-wight);
        font-size: var(--editor_detail-card-header-font-size);
        line-height: var(--editor_detail-card-header-line-height);
        min-height: var(--editor_detail-card-header-height);
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px 25px 7px 25px;
        justify-content: space-between;

        .collapse-icon {
            background-color: transparent;
            transition: 0.4s ease transform;
            transform: rotate(270deg);
        }

        button {
            font-size: var(--editor_detail-card-icon-font-size);
        }

        &.card-not-opened {
            .collapse-icon {
                transform: rotate(90deg);
            }
        }
    }

    &.card-not-opened.card__header {
        border-bottom-left-radius: var(--editor_detail-card-border-radius);
        border-bottom-right-radius: var(--editor_detail-card-border-radius);
    }

    &.card-not-opened {
        .card__header {
            .collapse-icon {
                transform: rotate(90deg);
            }
        }
    }

    &__body {
        padding: 0 25px 20px 25px;
        background-color: var(--editor_detail-card-body-background);
    }

    &__content {
        padding: 20px 0 0 0;
        margin: 0;
        line-height: 20px;
        border-top: var(--editor_detail-card-body-top-border);
    }

    &__footer {
        background-color: var(--editor_detail-card-footer-background);
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        color: var(--editor_detail-card-header-icon-color);
        font-size: var(--editor_detail-card-icon-font-size);
        transition-duration: 0.5s;
    }

    &__button-container {
        padding-bottom: 24px;

        &.right {
            padding-right: 20px;
            padding-left: 14px;
        }
    }

    &.card-not-opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.collapse-card .card__body {
    border-bottom-left-radius: var(--editor_detail-card-border-radius);
    border-bottom-right-radius: var(--editor_detail-card-border-radius);
}
