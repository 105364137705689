.flex-container {
    display: flex !important;
}

.w50 {
    width: 50px !important;
}

.w80 {
    width: 80px !important;
}

.w100 {
    width: 100px !important;
}

.w120 {
    width: 120px !important;
}

.w150 {
    width: 150px !important;
}

.section-font-color {
    color: var(--section-text-font-color);
}

.general-full-page-centered-container {
    height: calc(100vh - var(--header-height) - var(--footer-height) - 55px);
    width: 700px;
    max-width: 100%;
    margin: 0 auto;

    &.extended {
        width: 820px;
    }
}

.general-editor-input-field {
    border: 1px solid var(--editor-widget-color);
    padding: 10px;
    min-height: 42px;
    outline: none;
    width: 100%;
    font-size: 16px;
    font-family: var(--editor_main-font-family);
    transition: all 0.3s;
    color: var(--editor-main-font-color);

    &::placeholder {
        font-size: 16px;
        font-family: var(--editor_main-font-family);
        font-style: var(--editor-font-style-italic);
        color: var(--editor-placeholder-color);
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 3px var(--editor-widget-color);
    }
}

.general-editor-table {
    border-collapse: collapse;
    width: 100%;

    &__header {
        background-color: var(--editor_publish-modal-thead-background);
        color: var(--editor_publish-modal-thead-color);

        th {
            height: 42px;
            font-size: 14px;
            text-align: left;
            padding: 0 10px;
            border: 1px solid #243539;
        }
    }

    td,
    th {
        border: 1px solid #243539;
        text-align: left;
        padding: 8px 10px;
        font-weight: 500;
        font-size: 14px;

        &.action-container {
            width: 56px;

            .icon-only {
                span {
                    font-size: 20px;
                }
            }
        }
    }
}

.two-in-a-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 761px) {
    .two-in-a-row {
        grid-template-columns: 1fr;
        gap: 0;
    }
}
.reset-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
