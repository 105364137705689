.generic-input-reset {
    background: inherit;
    font-family: inherit;
    color: inherit;
    border: none;
    width: 100%;

    &::placeholder {
        opacity: 0.5;
        font-style: inherit;
        font-size: inherit;
        color: inherit;
    }
}
