.template-btn {
    display: flex;
    white-space: nowrap;
    width: fit-content;
    min-width: fit-content;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 1px solid;
    padding: 12px var(--btn-side-padding);
    text-decoration: none;
    font-size: calc(var(--template-base-font-size) / var(--template-base-ratio-size));
    line-height: 1.2;
    font-family: var(--template-secondary-font-family);
    font-weight: 500;
    transition: all 0.3s;
    border-radius: var(--button-border-radius);

    &.uppercase {
        text-transform: uppercase;
    }

    &.editor-btn {
        height: 50px;
    }

    &.btn-w100 {
        width: 100%;
    }

    &.height-small {
        height: 36px;
    }

    &.height-medium {
        height: 42px;
    }
    &.height-large {
        height: 50px;
    }

    &.left-icon {
        .btn-icon {
            margin-right: 10px;
            text-decoration: none;
        }
    }

    &.right-icon {
        .btn-icon {
            margin-left: 10px;
            text-decoration: none;
        }
    }

    &.icon-only {
        padding: 0 4px;
        margin: 0 4px;
        text-decoration: none;
        border: none;
    }

    &.card-btn {
        color: var(--card-button-text-color);
        background-color: var(--card-button-background-color);
        border-color: var(--card-button-border-color);

        &:hover,
        &:focus {
            background-color: var(--card-button-hover-background-color);
        }

        &.accent {
            background-color: var(--card-button-accent-background-color);
            color: var(--card-button-accent-text-color);
            border-color: var(--card-button-accent-border-color);

            &:hover,
            &:focus {
                background-color: var(--card-button-accent-hover-background-color);
            }
        }

        &:disabled,
        &[disabled],
        &.disabled {
            cursor: default;
            pointer-events: none;

            color: var(--card-button-disabled-text-color) !important;
            background-color: var(--card-button-disabled-background-color) !important;
            border-color: var(--card-button-disabled-border-color) !important;
        }
    }

    &.nav-card-btn {
        color: var(--nav-card-button-text-color);
        background-color: var(--nav-card-button-background-color);
        border-color: var(--nav-card-button-border-color);

        &:hover,
        &:focus {
            background-color: var(--nav-card-button-hover-background-color);
        }
    }

    &.section-btn {
        color: var(--section-button-text-color);
        background-color: var(--section-button-background-color);
        border-color: var(--section-button-border-color);

        &:hover,
        &:focus {
            color: var(--section-button-background-color);
            background-color: var(--section-button-text-color);
        }

        &:disabled {
            color: var(--section-button-disabled-text-color) !important;
            background-color: var(--section-button-disabled-background-color) !important;
            border-color: var(--section-button-disabled-border-color) !important;
        }
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--focus-border-color);
    }

    &:disabled,
    &[disabled],
    &.disabled {
        cursor: default;
        pointer-events: none;
        color: var(--tempate-button-disabled-color) !important;
        background-color: var(--tempate-button-disabled-background-color) !important;
        border-color: var(--tempate-button-disabled-border-color) !important;
    }
}
