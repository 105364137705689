@import 'src/assets/fonts/nitro-icons/nitroicon';
[data-tooltip] {
    --tooltip-transition-time: 0.2s;
    --tooltip-background: var(--editor_sidebar-tooltip-background);
    --tooltip-color: var(--editor_sidebar-tooltip-color);
    --tooltip-border: var(--editor_sidebar-tooltip-color);
    position: relative;
    display: inline-block;
    text-transform: none;

    &::before {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: all var(--tooltip-transition-time) ease;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        border: 1px solid var(--tooltip-border);
        content: attr(data-tooltip);
        white-space: nowrap;
        padding: 0.5rem 1rem;
        color: var(--tooltip-color);
        border-radius: 10px;
        background-color: var(--tooltip-background);
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        bottom: calc(100% + 5px);
    }

    &.tooltip-marker {
        &::after {
            font-family: 'nitroicon';
            content: nitroicon-font('tooltip_marker');
            color: inherit;
            pointer-events: none;
        }
    }

    &.tooltip-small {
        &::after {
            padding: 0.35rem 0.85rem;
            font-size: 0.85rem;
        }
    }

    &.tooltip-arrow {
        &::after {
            opacity: 0;
            content: '';
            pointer-events: none;
            position: absolute;
            border-top: none;
            border-right: none;
            border: 1px solid var(--tooltip-border);
            width: 8px;
            height: 8px;
            background-color: var(--tooltip-background);
            z-index: 1000;
        }
        &-top {
            &::after {
                bottom: 100%;
                right: calc(50% - 4px);
                transform: rotate(-45deg);
            }
        }
        &-bottom {
            &::after {
                top: 100%;
                left: calc(50% - 4px);
                transform: rotate(-225deg);
            }
        }
        &-right {
            &::after {
                left: calc(100%);
                top: calc(50% - 4px);
                transform: rotate(-315deg);
            }
        }
        &-left {
            &::after {
                top: calc(50% - 4px);
                transform: rotate(-135deg);
                right: calc(100%);
            }
        }
    }

    &.tooltip-storage {
        &::before {
            white-space: normal;
        }
    }

    &.tooltip-delay:hover {
        &::before {
            transition-delay: 0.2s;
        }
    }

    &.tooltip-multiline {
        &::before {
            white-space: pre;
        }
    }

    &.tooltip-bottom {
        &::before {
            bottom: unset;
            top: calc(100% + 5px);
        }
    }

    &.tooltip-left {
        &::before {
            top: 50%;
            transform: translateY(-50%);
            bottom: unset;
            left: unset;
            right: calc(100% + 5px);
        }
    }

    &.tooltip-right {
        &::before {
            top: 50%;
            transform: translateY(-50%);
            bottom: unset;
            right: unset;
            left: calc(100% + 5px);
        }
    }

    &:hover {
        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 1;
        }
        &:disabled,
        &.disabled {
            &::before {
                opacity: 0;
            }
        }
    }
}
