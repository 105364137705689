.editor-btn {
    --editor-btn-height: 50px;
    display: flex !important;
    white-space: nowrap;
    min-width: fit-content;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 1px solid;
    padding: 0 1rem;
    text-decoration: none;
    font-size: var(--editor-font-size-normal);
    height: var(--editor-btn-height);
    font-family: var(--editor_secondary-font-family);
    font-weight: 500;
    transition: all 0.3s;
    border-radius: 0;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px var(--editor_button-focus-color);
        z-index: 1;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.btn-w100 {
        width: 100%;
    }

    &.height-extra-small {
        --editor-btn-height: 18px;
    }

    &.height-small {
        --editor-btn-height: 36px;
    }

    &.height-medium {
        --editor-btn-height: 42px;
    }
    &.height-large {
        --editor-btn-height: 50px;
    }

    &.radius-0 {
        border-radius: 0;
    }
    &.radius-8 {
        border-radius: 0.5rem;
    }
    &.radius-16 {
        border-radius: 1rem;
    }
    &.radius-25 {
        border-radius: calc(var(--editor-btn-height) / 2);
    }

    &.border-0 {
        border-width: 0;
    }
    &.border-1 {
        border-width: 1px;
    }
    &.border-2 {
        border-width: 2px;
    }

    &.primary {
        background-color: var(--editor_button-primary-background-color);
        color: var(--editor_button-primary-text-color);
        border-color: var(--editor_button-primary-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-primary-hover-background-color);
            color: var(--editor_button-primary-hover-text-color);
        }
    }

    &.secondary {
        background-color: var(--editor_button-secondary-background-color);
        color: var(--editor_button-secondary-text-color);
        border-color: var(--editor_button-secondary-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-secondary-hover-background-color);
            color: var(--editor_button-secondary-hover-text-color);
        }
    }

    &.secondary-blue {
        background-color: var(--editor_button-secondary-blue-background-color);
        color: var(--editor_button-secondary-text-color);
        border-color: var(--editor_button-secondary-blue-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-secondary-blue-hover-background-color);
        }
    }

    &.danger {
        background-color: var(--editor_button-danger-background-color);
        color: var(--editor_button-danger-text-color);
        border-color: var(--editor_button-danger-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-danger-hover-background-color);
            color: var(--editor_button-danger-hover-text-color);
        }
    }

    &.orange {
        background-color: var(--editor_button-orange-background-color);
        color: var(--editor_button-orange-text-color);
        border-color: var(--editor_button-orange-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-orange-hover-background-color);
            color: var(--editor_button-orange-hover-text-color);
        }
    }

    &.accent {
        background-color: var(--editor_button-accent-background-color);
        color: var(--editor_button-accent-text-color);
        border-color: var(--editor_button-accent-border-color);
        &:not(.link):hover {
            background-color: var(--editor_button-accent-hover-background-color);
            color: var(--editor_button-accent-hover-text-color);
        }
    }

    &.section-btn {
        color: var(--section-button-text-color);
        background-color: var(--section-button-background-color);
        border-color: var(--section-button-border-color);
        &.accent,
        &:hover,
        &:focus {
            color: var(--section-button-background-color);
            background-color: var(--section-button-text-color);
        }

        &:disabled {
            color: var(--section-button-disabled-text-color) !important;
            background-color: var(--section-button-disabled-background-color) !important;
            border-color: var(--section-button-disabled-border-color) !important;
        }
    }

    &.ghost {
        border: none;
    }

    .editor-btn-icon {
        font-size: 1.25em;
    }

    &.left-icon {
        .editor-btn-icon {
            margin-right: 0.45rem;
            text-decoration: none;
        }
    }

    &.right-icon {
        .editor-btn-icon {
            margin-left: 0.45rem;
            text-decoration: none;
        }
    }

    &.icon-only {
        width: var(--editor-btn-height);
        padding: 0;
    }

    &:disabled,
    &[disabled],
    &.disabled {
        cursor: default;
        pointer-events: none;
        color: var(--tempate-button-disabled-color) !important;
        background-color: var(--tempate-button-disabled-background-color) !important;
        border-color: var(--tempate-button-disabled-border-color) !important;
    }
}
